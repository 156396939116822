<template>
  <transition name="fade" mode="out-in">
    <ui-loader v-if="isLoading" />
    <div v-if="!isLoading">
      <nav class="navbar has-margin-bottom">
        <a @click="$router.go(-1)" class="navbar-item">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>{{ $t('Views.Terms.BookingTermDetail.Link_Back') }}</span>
        </a>
      </nav>

      <div class="container">
        <div class="columns is-multiline">
          <div class="wrapper column is-half">
            <ui-section-header
              additionalClass="has-margin-bottom"
              :showEdit="term.Status == 0"
              :editFunction="openEditName"
            >
              <template v-slot:title>{{ term.Version }}</template>
            </ui-section-header>

            <Message
              v-if="term.Version === ''"
              type="is-danger"
              :message="$t('Views.Terms.BookingTermDetail.Message_NoName')"
            ></Message>

            <ModelEditVersion
              v-if="showEditTermName"
              :showModal="showEditTermName"
              :onClickCancel="hideModal"
              :term="term"
              @termUpdated="setTermData"
            />
          </div>

          <div class="column is-full">
            <ModelEditText
              v-if="showEditTermText"
              :showModal="showEditTermText"
              :onClickCancel="hideModal"
              :term="term"
              @termUpdated="setTermData"
            />

            <ui-section-header
              :showEdit="term.Status === 0"
              :editFunction="openEditText"
              :titleClass="'title is-4'"
            >
              <template v-slot:title>{{
                $t('Views.Terms.BookingTermDetail.HeaderTitle_ChangesTerms')
              }}</template>
            </ui-section-header>

            <div class="columns is-multiline">
              <div
                v-for="(language, index) in term.Languages"
                :key="index"
                class="column is-half"
              >
                <div class="title is-5">
                  {{ language.Language }}
                </div>

                <div class="textWrapper has-padding">
                  <div class="">
                    <strong>{{
                      $t('Views.Terms.BookingTermDetail.Label_Changes')
                    }}</strong
                    ><br />
                    <div v-html="language.Changes"></div>
                  </div>

                  <div class="is-divider"></div>

                  <div class="has-margin-bottom has-text-weight-bold">
                    {{ $t('Views.Terms.BookingTermDetail.Label_Terms') }}
                  </div>
                  <div class="content" v-html="language.Terms"></div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="term.Type === 'BookingTerm'" class="column is-full">
            <div class="tabs is-boxed">
              <ul>
                <li
                  v-for="meetingType in channel.Meetingtypes"
                  @click="setSelectedMeetingtypeId(meetingType.MeetingtypeId)"
                  :class="{
                    'is-active':
                      meetingType.MeetingtypeId === selectedMeetingtypeId,
                  }"
                  :key="meetingType.MeetingtypeId"
                >
                  <a>{{ meetingType.MeetingtypeId | getMeetingtypeName }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div v-if="term.Type === 'BookingTerm'" class="column is-full">
            <ModelEditCancelRules
              v-if="showEditCancelRules"
              :showModal="showEditCancelRules"
              :onClickCancel="hideModal"
              :term="term"
              @termUpdated="getTermsDetail"
            />

            <ui-section-header
              :showEdit="term.Status === 0"
              :editFunction="openCancelRules"
              :titleClass="'title is-4'"
            >
              <template v-slot:title>
                {{ $t('Views.Terms.BookingTermDetail.Header_CancelRules') }}
              </template>
            </ui-section-header>

            <table class="table is-fullwidth is-striped is-narrow">
              <thead>
                <tr>
                  <th>
                    {{
                      $t(
                        'Views.Terms.BookingTermDetail.TableHeader_DaysTillBooking'
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t('Views.Terms.BookingTermDetail.TableHeader_Percentage')
                    }}
                  </th>
                  <th>
                    {{
                      $t('Views.Terms.BookingTermDetail.TableHeader_GroupSize')
                    }}
                  </th>
                  <th>
                    {{
                      $t('Views.Terms.BookingTermDetail.TableHeader_MinSeats')
                    }}
                  </th>
                  <th>
                    {{
                      $t('Views.Terms.BookingTermDetail.TableHeader_MaxSeats')
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="showMessageNoRules" key="NoConfig">
                  <td colspan="6">
                    <article class="message is-warning mb-2 mt-2">
                      <div class="message-body">
                        {{
                          $t('Views.Terms.BookingTermDetail.Message_Warning')
                        }}
                      </div>
                    </article>
                  </td>
                </tr>
                <tr v-for="cancelRule in term.CancelRules" :key="cancelRule.Id">
                  <td v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId">
                    {{ Math.floor(cancelRule.HoursUntilStart / 24) }}
                  </td>
                  <td v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId">
                    {{ cancelRule.Percentage }}%
                  </td>
                  <td v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId">
                    <font-awesome-icon
                      v-if="
                        cancelRule.MinSeats > -1 && cancelRule.MaxSeats > -1
                      "
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-if="
                        cancelRule.MinSeats === -1 && cancelRule.MaxSeats === -1
                      "
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </td>
                  <td v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId">
                    {{ cancelRule.MinSeats > -1 ? cancelRule.MinSeats : '--' }}
                  </td>
                  <td v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId">
                    {{ cancelRule.MaxSeats > -1 ? cancelRule.MaxSeats : '--' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import termsProvider from '@/providers/terms'

export default {
  components: {
    //Message: () => import(/* webpackChunkName: "term-detail" */ '@/components/UI/Message'),
    ModelEditVersion: () =>
      import(
        /* webpackChunkName: "term-detail" */ '@/components/Terms/ModalEditTermName'
      ),
    ModelEditText: () =>
      import(
        /* webpackChunkName: "term-detail" */ '@/components/Terms/ModalEditTermText'
      ),
    ModelEditCancelRules: () =>
      import(
        /* webpackChunkName: "term-detail" */ '@/components/Terms/ModalEditCancelRules'
      ),
  },

  props: {},

  data() {
    return {
      termId: Number(this.$route.params.termId),
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      isLoading: false,
      showEditTermName: false,
      showEditTermText: false,
      showEditCancelRules: false,
      selectedMeetingtype: null,
      selectedMeetingtypeId: 1,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('termStore', ['term']),

    showMessageNoRules() {
      if (this.terms && this.term.CancelRules) {
        let typeIndex = this.term.CancelRules.findIndex(
          (rule) => rule.MeetingtypeId === this.selectedMeetingtypeId
        )
        if (typeIndex === -1 || this.term.CancelRules.length === 0) {
          return true
        }
      }
      return false
    },
  },

  created() {},

  methods: {
    getTermsDetail() {
      let self = this
      if (!self.isLoading) {
        self.isLoading = true
        termsProvider.methods
          .getTermById(this.termId)
          .then((response) => {
            self.term = response.data
          })
          .catch((e) => {})
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    setTermData(term) {
      this.term = term
    },

    openEditName() {
      this.showEditTermName = true
    },

    openEditText() {
      this.showEditTermText = true
    },

    openCancelRules() {
      this.showEditCancelRules = true
    },

    hideModal() {
      this.showEditTermName = false
      this.showEditTermText = false
      this.showEditCancelRules = false
    },

    setSelectedMeetingtypeId(meetingtypeId) {
      let self = this
      self.selectedMeetingtype = null
      self.selectedMeetingtypeId = meetingtypeId

      let meetingtypeIndex = self.channel.Meetingtypes.findIndex(
        (m) => m.MeetingtypeId === meetingtypeId
      )
      if (meetingtypeIndex > -1) {
        self.selectedMeetingtype =
          self.locationState.Meetingtypes[meetingtypeIndex]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.textWrapper {
  border: 1px solid $grey-lighter;
  overflow-y: scroll;
  width: 100%;
  height: 300px;
}
</style>
